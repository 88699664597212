import React from 'react';
import * as Constants from './Constants'

const Footer = () => {
    return (
        <footer id="footer">
            <ul className="icons">
                <li><a href={Constants.LINKEDIN_URL} className="icon brands fa-linkedin"><span className="label"></span></a></li>
                <li><a href={Constants.GITHUB_URL} className="icon brands fa-github"><span className="label"></span></a></li>
                <li><a href={Constants.MEDIUM_URL} className="icon brands fa-medium"><span className="label"></span></a></li>
            </ul>
            <ul className="copyright">
                <li>Copyright Soundwood</li>
                <li>Design: <a href={Constants.HTML5UP_URL} >HTML5 UP</a></li>
            </ul>
        </footer>
    );
};

export default Footer;