import React, { Component } from 'react'

const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
function validate({contactName, contactEmail}) {
    return {
        contactName: contactName.length === 0,
        contactEmail: contactEmail.length === 0 || !mailformat.test(contactEmail)
    }
}

export default class ComponentsEx1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contactName: "",
            contactEmail: "",
            contactMessage: "",
            selectedOption: null,
            errors: {
                contactName: false,
                contactEmail: false,
                contactMessage: false,
                initialize: true
            }
        }
    }
    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({[name]: value, errors: validate(this.state)})
    }
    handleSubmit = (e) => {
        e.preventDefault()
        if (Object.keys(this.state.errors).some(x => this.state.errors[x])) {
                alert("Please complete the form with valid information")
            } else {
                this.setState({ contactName: "", contactEmail: "", contactMessage: "", selectedOption: null, errors: {initialize: true} })
                alert("Information successfully submitted")
            }
    }
    handleOptionChange = (e) => {
        const optionVal = e.target.value
        this.setState({selectedOption: optionVal})
    }
    render() {
        const isEnabled = !Object.keys(this.state.errors).some(x => this.state.errors[x])
        return (
            <li className="icon fa-paper-plane">
                <h3>Simple Controlled Form with disabled Submit Button</h3>
                <form onSubmit={this.handleSubmit}>
                    <label>Your Name *</label>
                    <input type="text" value={this.state.contactName} name="contactName" onChange={this.handleChange} className={this.state.errors.contactName ? "error" : ""}/><br/>
                    <label>Your Email *</label>
                    <input type="text" value={this.state.contactEmail} name="contactEmail" onChange={this.handleChange} className={this.state.errors.contactEmail ? "error" : ""}/><br/>
                    <label>Quick Message</label>
                    <textarea type="text" rows="4" value={this.state.contactMessage} name="contactMessage" onChange={this.handleChange}/><br/>
                    <label>Do you wanna play a game?</label>
                    <input type="radio" value="yes" onChange={this.handleOptionChange} checked={this.state.selectedOption === "yes" } />Yes<br/>
                    <input type="radio" value="no" onChange={this.handleOptionChange} checked={this.state.selectedOption === "no" } />No<br/>
                    <br/>
                    <input type="submit" value="Submit" disabled={!isEnabled}/>
                </form>
            </li>
        )
    }
}
