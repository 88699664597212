import React from 'react';
import PortfoliShamr from './PortfolioShamr'
import PortfolioSmarthomecost from './PortfolioSmarthomecost'
import PortfolioIntbuff from './PortfolioIntbuff'
import PortfolioStonnksBot from './PortfolioStonksBot';

const PortfolioContainer = () => {
    return (
        <section id="portfolio" className="wrapper alt style2">
            <PortfolioStonnksBot />
            <PortfoliShamr />
            <PortfolioSmarthomecost />
            <PortfolioIntbuff />
        </section>
    );
};

export default PortfolioContainer;