import React, { Component } from 'react'
import AboutContainer from './AboutContainer'
import PortfolioContainer from './PortfolioContainer'
import BlogContainer from './BlogContainer'
import Cta from './Cta'
import Banner from './Banner'
import Footer from './Footer'
import Header from './Header'
import Navbar from './Navbar'

export default class Home extends Component {
    handleMenuClick = () => {
        let body = document.getElementById("main-body")
        body.className = "landing is-menu-visible"
    }
    render() {
        return (
            <>
                <div id="page-wrapper">
                    <Header handleMenuClick={this.handleMenuClick}/>
                    <Banner />
                    <AboutContainer /><br/>
                    <PortfolioContainer /><br/>
                    <BlogContainer />
                    <Cta />
                    <Footer />
                </div>
                <div id="menu">
                    <Navbar />
                </div>
            </>
        )
    }
}