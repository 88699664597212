import React from 'react';
import * as Constants from './Constants'

const PortfolioSmarthomecost = () => {
    return (
        <section className="spotlight">
            <div className="image">
                <a href={Constants.SMARTHOMECOST_GITHUB}>
                    <img src="/images/SMARTHOMECOST_FULL_SITE.gif" alt="name of thing" />
                </a>
            </div>
            <div className="content">
                <h2>SMART HOME COST</h2>
                <p>SMART HOME COST is intended to be used with the HOMECALC API backend. The site allows a user to login and signup and keep track of their 
                    own 'scenarios'. These 'scenarios' include information like net income credit score and total monthly debt. </p>
                <p>No need to provide local property tax rates or mortgage interest rates. Those values are calculated for you based on the provided location 
                    and credit score. SMART HOME COST then uses this information and several 'Rules of Thumb' to determine 
                    how much home the user, given that particular scenario, can afford. </p>
                <p>The analysis of each scenario are arranged from most to least frugal and 
                    provide some additional information such as the resulting monthly mortgage and tax costs.</p>
            </div>
        </section>
    );
};

export default PortfolioSmarthomecost;