import React, { Component } from 'react'
// import { Link } from "react-router-dom"
import { HashLink as Link } from 'react-router-hash-link'

export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.handleOutsideClick.bind(this)
        this.handleMenuSelect.bind(this)
    }
    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick)
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick)
    }
    handleOutsideClick(e) {
        // this is so very very ugly
        if (e.target.parentElement.parentElement.parentElement.id === "nav" || e.target.id === "span-menu") {
            return;
        } else {
            let body = document.getElementById("main-body")
            body.className = "landing"
        }
    }
    handleMenuSelect = () => {
        let body = document.getElementById("main-body")
        body.className = "landing"
    }
    render() {
        return (
            <nav id="nav">
                <ul>
                    <li onClick={this.handleMenuSelect}><Link to="/" className="active">Home</Link></li>
                    <li onClick={this.handleMenuSelect}><Link to="/#about" className="active">About</Link></li>
                    <li onClick={this.handleMenuSelect}><Link to="/#portfolio" className="active">Portfolio</Link></li>
                    <li onClick={this.handleMenuSelect}><Link to="/#blog" className="active">Blog</Link></li>
                    <li onClick={this.handleMenuSelect}><Link to="/#cta" className="active">Contact</Link></li>
                    <li onClick={this.handleMenuSelect}><Link to="componentexpo" className="active">Component Expo!</Link></li>
                </ul>
            </nav>
        )
    }
}
