import React from 'react';

const Banner = () => {
    return (
        <section id="banner">
            <div className="inner">
                <h2>Hey! I'm Ben</h2>
                <p>Full Stack Developer and Manufacturing Specialist</p>
            </div>
        </section>
    );
};

export default Banner;