export const LINKEDIN_URL = "https://www.linkedin.com/in/jbenhogan/"
export const GITHUB_URL = "https://github.com/Soundwood"
export const MEDIUM_URL = "https://jbenhogan.medium.com/"
export const HTML5UP_URL = "http://html5up.net"
export const SOCIAL_CREDIT_WIKI = "https://en.wikipedia.org/wiki/Social_Credit_System"

export const EMAILJS_SERVICEID = "service_xephomp"
export const EMAILJS_TEMPLATEID = "template_hl43bfb"
export const EMAILJS_USERID = "user_SH6G7aFP2SAH0tJ7Q6wb8"

export const SMARTHOMECOST_GITHUB = "https://github.com/Soundwood/HomeCalc-frontend"
export const SHAMR_GITHUB = "https://github.com/Soundwood/SHAMR-REACT"
export const INTBUFF_GITHUB = "https://github.com/Soundwood/INTbuff"
export const STONKSBOT_GITHUB = "https://github.com/Soundwood/stonks-alert-frontend"
export const GROUPTASK_GITHUB = "https://github.com/Soundwood/GroupTask"

export const MEDIUM_BLOG_1 = "https://jbenhogan.medium.com/flatiron-final-project-the-edge-details-smart-home-cost-9e1456bc9f9"
export const MEDIUM_BLOG_2 = "https://jbenhogan.medium.com/this-blog-was-originally-posted-to-flatirons-blog-dashboard-on-october-11-2020-and-contains-2bfa9f39df63"

export const BACKEND_API_BASE = 'https://api.soundwooddev.com'
export const BACKEND_API_TOKEN = '123123123'