import React from 'react';

const Header = (props) => {
    return (
        <header id="header" className="alt">
            <h1>Soundwood Portfolio</h1>
            <nav id="nav">
                <ul>
                    <li className="special">
                        <a href="#banner" onClick={(e) => e.preventDefault()} className="menuToggle">
                            <span id="span-menu" onClick={props.handleMenuClick}>Menu</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;