import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from './Home'
import ComponentExpo from './ComponentExpo'

export default class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/componentexpo' component={ComponentExpo} />
                </Switch>
            </Router>
        )
    }
}
