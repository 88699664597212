import React from 'react';
import * as Constants from './Constants'

const PortfolioIntbuff = () => {
    return (
        <section className="spotlight">
            <div className="image">
                <a href={Constants.INTBUFF_GITHUB}>
                <img src="/images/INTBUFF_FULL_SITE.gif" alt="name of thing" />
                </a>
            </div>
            <div className="content">
                <h2>INTbuff</h2>
                <p>INTbuff (short for Intelligence Buff) is a Ruby on Rails Application intended to provide a location for storage of 'Educational Pursuits' 
                    such as courses, tutorials, and other resources. A user can log in to the application via INTBUFF or third party OAUTH and have the ability 
                    to save these pursuits to their profile. Users can also add notes to their educational pursuits.</p>
                <p>The app is built on RESTful principles and supports basic CRUD functionality</p>
            </div>
        </section>
    );
};

export default PortfolioIntbuff;