import React from 'react';
import * as Constants from './Constants'

const Blog1 = () => {
    return (
        <li className="icon fa-paper-plane">
            <h3>Flatiron Final Project: The Edge Details — SMART HOME COST</h3>
            <p>For the Capstone Flatiron react-redux project I chose to make a tool that I wish I had available when I 
                first started looking for homes. That is an analysis on how much home I can reasonably afford. There are 
                several “Rules” for determining this, though all of them are based on only a few variables. By asking the 
                user... <a href={Constants.MEDIUM_BLOG_1}>Read more on Medium</a></p>
        </li>
    );
};

export default Blog1;