import React from 'react';
import * as Constants from './Constants'

const PortfolioStonksBot = () => {
    return (
        <section className="spotlight">
            <div className="image">
                <a href={Constants.STONKSBOT_GITHUB}>
                <img src="/images/STONKSBOT_FULL_SITE.png" alt="stonks bot front page" />
                </a>
            </div>
            <div className="content">
                <h2>Stonk Trend Bot</h2>
                <p>Born out of the reddit r/wallstreetbets craze of 2021. This API, website and system of bots sets out to aggregate posts from several
                    social media sites and present the most popular ones to the user. The information is hosted via AWS Beanstalk, Route 53, RDS and 
                    EC2. The reddit bot interfaces through the reddit API and the ruby redd wrapper gem. 
                </p>
            </div>
        </section>
    );
};

export default PortfolioStonksBot;