import React, { Component } from 'react'
import emailjs from 'emailjs-com'
import * as Constants from './Constants'

const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
function validate({contactName, contactEmail}) {
    return {
        contactName: contactName.length === 0,
        contactEmail: contactEmail.length === 0 || !mailformat.test(contactEmail)
    }
}

export default class Cta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contactName: "",
            contactEmail: "",
            contactMessage: "",
            errors: {
                contactName: false,
                contactEmail: false,
                contactMessage: false
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({[name]: value, errors: validate(this.state)})
    }
    handleSubmit = (e) => {
        e.preventDefault()
        if (Object.keys(this.state.errors).some(x => this.state.errors[x])) {
                alert("Please complete the form with valid information")
            } else {
                emailjs.sendForm(Constants.EMAILJS_SERVICEID, Constants.EMAILJS_TEMPLATEID, e.target, Constants.EMAILJS_USERID)
                .then(function(response) {
                    console.log('SUCCESS!', response.status, response.text);
                }, function(error) {
                    console.log('FAILED...', error);
                })
                this.setState({ contactName: "", contactEmail: "", contactMessage: "" })
                alert("Information successfully submitted")
            }
    }
    render() {
        return (
            <section id="cta" className="wrapper style4">
            <div className="inner">
                <header>
                    <h2>Contact Me</h2>
                    <form onSubmit={this.handleSubmit}>
                        <label>Your Name</label>
                        <input type="text" value={this.state.contactName} name="contactName" onChange={this.handleChange} className={this.state.errors.contactName ? "error" : ""}/><br/>
                        <label>Your Email</label>
                        <input type="text" value={this.state.contactEmail} name="contactEmail" onChange={this.handleChange} className={this.state.errors.contactEmail ? "error" : ""}/><br/>
                        <label>Quick Message</label>
                        <textarea type="text" rows="4" value={this.state.contactMessage} name="contactMessage" onChange={this.handleChange}/><br/>
                        <input type="submit" value="Submit" />
                    </form>
                </header>
                {/* <ul className="actions stacked">
                    <li><a href="#about" className="button fit primary">thing1</a></li>
                    <li><a href="#about" className="button fit">thing 2</a></li>
                </ul> */}
            </div>
        </section>
        )
    }
}
