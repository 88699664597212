import React, { Component } from 'react'

export default class ComponentsEx2 extends Component {
    render() {
        return (
            <li className="icon fa-paper-plane">
                <h3>Dropdown Search</h3>
                <p>...Under Construction...</p>
            </li>
        )
    }
}
