import React, { Component } from 'react'
import Blog1 from './Blog1'
import Blog2 from './Blog2'
import * as Constants from './Constants'


export default class BlogContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blog_data: []
        }
    }
    componentDidMount() {
        this.getBlogData()
    }
    getBlogData = () => {
        fetch(`${Constants.BACKEND_API_BASE}/blogs`, {
            // headers: {
            //     "Authorization": `Token ${process.env.REACT_APP_BACKEND_API_TOKEN}`
            // }
        })
        .then(res => res.json())
        .then(json => {
            console.log(json)
            this.setState({blog_data: json})
        })
    }
    render() {
        return (
            <section id="blog" className="wrapper style3 special">
                <div className="inner">
                    <header className="major">
                        <h2>Blogs and Writings</h2>
                        <p>I write about tech, manufacturing, and other topics of general interest</p>
                    </header>
                    <ul className="features">
                        <Blog1 />
                        <Blog2 />
                    </ul>
                </div>
            </section>
        )
    }
}