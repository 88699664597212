import React from 'react';

const AboutContainer = () => {
    return (
        <section id="about" className="wrapper style1 special">
            <div className="inner">
                    <header className="major">
                        <h2>About Me</h2>
                        <p>I am an Industrial Engineer from the manufacturing industry turned software engineer with over a decade of 
                            work experience in heavy manufacturing environments. I realized through my daily work life that more and more of the 
                            problems the manufacturing sector faced were those that could be resolved through the proper implementation of 
                            technology. Out of a desire to help drive the transition, I chose to make a change and add software engineering to my toolkit.</p>
                        <p>Hover over any of the icons below to see their names.</p>
                    </header>
                    <ul className="icons major">
                        <li><span className="icon major style1"><img src='/ICONS/RUBY_ICON.png' title="Ruby Language" alt="Ruby Language" width="100%" /></span></li>
                        <li><span className="icon major style1"><img src='/ICONS/POSTGRESQL_ICON.svg' title="PostgreSQL" alt="PostgreSQL" width="100%" /></span></li>
                        <li><span className="icon major style1"><img src='/ICONS/AUTODESK_INVENTOR_ICON.jpg' title="Autodesk Inventor" alt="Autodesk Inventor" width="100%"/></span></li>
                        <li><span className="icon major style1"><img src='/ICONS/JAVASCRIPT_ICON.svg' title="JavaScript" alt="Javascript" width="100%"/></span></li>
                    </ul><ul className="icons major">
                        <li><span className="icon major style1"><img src='/ICONS/REACT_ICON2.png' title="React Language" alt="React Language" width="100%"/></span></li>
                        <li><span className="icon major style1"><img src='/ICONS/AUTOCAD_ICON.png' title="Autodesk Autocad" alt="Autodesk Autocad" width="100%"/></span></li>
                        <li><span className="icon major style1"><img src='/ICONS/REDUX_ICON.svg' title="Redux" alt="Redux" width="100%"/></span></li>
                        <li><span className="icon major style1"><img src='/ICONS/FUSION_ICON.jpg' title="Autodesk Fusion360" alt="Autodesk Fusion360" width="100%"/></span></li>
                        {/* <li><span className="icon major style1"><img src='/ICONS/SHAREPOINT_ICON.png' alt="Sharepoint" width="100%"/></span></li> */}
                    </ul>
                </div>
        </section>
    );
};

export default AboutContainer;