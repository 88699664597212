import React from 'react';
import * as Constants from './Constants'

const PortfolioShamr = () => {
    return (
        <section className="spotlight">
            <div className="image">
                <a href={Constants.SHAMR_GITHUB}>
                    <img src="/images/SHAMR_FULL_SITE.gif" alt="name of thing" />
                </a>
            </div>
            <div className="content">
                <h2>SHAMR</h2>
                <p>SHAMR is meant to be an exercise in 'bad tech' in that this is not expected to be taken entirely seriously. While watching a PBS broadcast, 
                    the topic of the 'Chinese Social Credit System' was covered. You can read more about it <a href={Constants.SOCIAL_CREDIT_WIKI}>
                    here</a>. I undertook this project to explore how tech can be used against us and ways in which it might go wrong. </p>
                    <p>SHAMR can be used to create 'offenders' and associated 'offenses'. These elements are persisted to the backend API and 
                    viewable by anyone that visits the site. A user can also enter a twitter user to check if that user follows anyone in the 'offenders' list. </p>
                    <p>Have fun and don't take this too seriously.</p>
            </div>
        </section>
    );
};

export default PortfolioShamr;