import React, { Component } from 'react'
import Header from './Header'
import Footer from './Footer'
import Navbar from './Navbar'
import ComponentsEx1 from './ComponentsEx1'
import ComponentsEx2 from './ComponentsEx2'

export default class ComponentExpo extends Component {
    handleMenuClick = () => {
        let body = document.getElementById("main-body")
        body.className = "landing is-menu-visible"
    }
    render() {
        return (
            <>
                <div id="page-wrapper">
                    <Header handleMenuClick={this.handleMenuClick}/>
                    <section id="blog" className="wrapper style3 special">
                        <div className="inner">
                            <header className="major">
                                <h2>A Cornucopia of Components!</h2>
                                <p>Below are components that I find useful/interesting</p>
                            </header>
                            <ul className="features">
                                <ComponentsEx1/>
                                <ComponentsEx2/>
                            </ul>
                        </div>
                    </section>
                    <Footer />
                </div>
                <div id="menu">
                    <Navbar />
                </div>
            </>
        )
    }
}
