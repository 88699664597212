import React from 'react';
import * as Constants from './Constants'

const Blog2 = () => {
    return (
        <li className="icon fa-paper-plane">
            <h3>SHAMR — Shame Your Enemies! (or friends)</h3>
            <p>For this project I wanted to have a bit of fun. I had seen a report on the ‘Chinese’ 
                Social Credit System’ and was reminded how tech can be both a great tool and an instrument 
                for bad actors. In an effort to explore this idea further I decided to... <a href={Constants.MEDIUM_BLOG_2}>Read more on Medium</a></p>
        </li>
    );
};

export default Blog2;